const State = [
        {
            images: "./Assets/single.png",
            title: "Single User",
            price: "$190",
            storage: "500 GB Storage",
            user: "1 Granted User",
            send: "Send up to 2 GB"
        },
        {
            images: "./Assets/double.png",
            title: "Double User",
            price: "$149",
            storage: "600 GB Storage",
            user: "2 Granted Users",
            send: "Send up to 5 GB"
        },
        {
            images: "./Assets/triple.png",
            title: "Triple User",
            price: "$300",
            storage: "900 GB Storage",
            user: "3 Granted Users",
            send: "Send up to 10 GB"
        },
    ]

export default State
